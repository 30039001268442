<ul class="navbar-nav ml-auto">
  <li>
    <a (click)="exampleModalCenter.show()" class="copyPointer">
      <i class="icon feather icon-power"></i>
    </a>
  </li>

</ul>

<app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Log Out</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="exampleModalCenter.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <p style="color:black">Are you sure you want to logout?</p>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal"
      (click)="exampleModalCenter.hide()">Close</button>
    <button type="button" (click)="onLogOut()" class="btn btn-primary ml-3">Confirm</button>
  </div>
</app-ui-modal>