import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './gaurd/auth.guard';
import { DashboardGuard } from './gaurd/dashboard.guard';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      // },
      // {
      //   path: 'layout',
      //   loadChildren: () => import('./demo/pages/layout/layout.module').then(module => module.LayoutModule)
      // },
      // {
      //   path: 'basic',
      //   loadChildren: () => import('./demo/ui-elements/ui-basic/ui-basic.module').then(module => module.UiBasicModule)
      // },
      // {
      //   path: 'forms',
      //   loadChildren: () => import('./demo/pages/form-elements/form-elements.module').then(module => module.FormElementsModule)
      // },
      // {
      //   path: 'tbl-bootstrap',
      //   loadChildren: () => import('./demo/pages/tables/tbl-bootstrap/tbl-bootstrap.module').then(module => module.TblBootstrapModule)
      // },
      // {
      //   path: 'charts',
      //   loadChildren: () => import('./demo/pages/core-chart/core-chart.module').then(module => module.CoreChartModule)
      // },
      // {
      //   path: 'maps',
      //   loadChildren: () => import('./demo/pages/core-maps/core-maps.module').then(module => module.CoreMapsModule)
      // },
      // {
      //   path: 'sample-page',
      //   loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      // }
      {
        path: 'home', loadChildren: () => import('./pages/dashboard-management/dashboard-management.module').then(module => module.DashboardManagementModule)

      },
      {
        path: 'transaction', loadChildren: () => import('./pages/transaction-management/transaction-management.module').then(module => module.TransactionManagementModule)
      },
      {
        path: 'swap', loadChildren: () => import('./pages/swap-management/swap-management.module').then(module => module.SwapManagementModule)
      },
      {
        path: 'fee', loadChildren: () => import('./pages/fee-management/fee-management.module').then(module => module.FeeManagementModule)
      },
      {
        path: 'static', loadChildren: () => import('./pages/static-content/static-content.module').then(module => module.StaticContentModule)
      },
      {
        path: 'myProfile', loadChildren: () => import('./pages/my-profile/my-profile.module').then(module => module.MyProfileModule)
      },
      // {
      //   path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(module => module.AuthModule)
      // }

    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      },

    ],
    canActivate: [DashboardGuard]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
