import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {

  constructor(public service : MainService) { }

  ngOnInit() { }
  openLogOutModal(){

  }
  onLogOut(){
    this.service.onLogout()
  }
}
