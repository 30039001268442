import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {

    type: 'group',

    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/home',
        classes: 'nav-item',
        icon: 'feather icon-home'
      },
      {
        id: 'transaction',
        title: 'Transaction Management',
        type: 'item',
        url: '/transaction',
        classes: 'nav-item',
        icon: 'feather icon-trending-up'
      },
      {
        id: 'swap',
        title: 'Swap Management',
        type: 'item',
        url: '/swap',
        classes: 'nav-item',
        icon: 'feather icon-repeat'
      },

      {
        id: 'fee',
        title: 'Fee Management',
        type: 'item',
        url: '/fee',
        classes: 'nav-item',
        icon: 'feather icon-zap'
      },

      {
        id: 'static',
        title: 'Static Management',
        type: 'item',
        url: '/static',
        classes: 'nav-item',
        icon: 'feather icon-settings'
      },
      {
        id: 'profile',
        title: 'Profile Management',
        type: 'item',
        url: '/myProfile/profile',
        classes: 'nav-item',
        icon: 'feather icon-user'
      },
    ]
  },

  // {
  //   id: 'profile',
  //   title: 'Profile',
  //   type: 'group',
  //   icon: 'feather icon-file-text',
  //   children: [
  //     {
  //       id: 'pro',
  //       title: 'Profile-Management',
  //       type: 'collapse',
  //       icon: 'feather icon-lock',
  //       children: [
  //         {
  //           id: 'profile',
  //           title: 'Profile Management',
  //           type: 'item',
  //           url: '/myProfile/profile',
  //           classes: 'nav-item',
  //           icon: 'feather icon-user'
  //         },
  //         {
  //           id: 'change-password',
  //           title: 'Change Password',
  //           type: 'item',
  //           url: '/myProfile/change-passsword',
  //           classes: 'nav-item',
  //           icon: 'feather icon-user'
  //         },

  //       ]
  //     },

  //   ]
  // },
  // {
  //   id: 'pages',
  //   title: 'Pages',
  //   type: 'group',
  //   icon: 'feather icon-file-text',
  //   children: [
  //     {
  //       id: 'auth',
  //       title: 'Authentication',
  //       type: 'collapse',
  //       icon: 'feather icon-lock',
  //       children: [
  //         {
  //           id: 'signup',
  //           title: 'Sign Up',
  //           type: 'item',
  //           url: '/auth/signUp',
  //           classes: 'nav-item',
  //           icon: 'feather icon-user'
  //         },
  //         {
  //           id: 'signin',
  //           title: 'Sign In',
  //           type: 'item',
  //           url: '/auth/login',
  //           classes: 'nav-item',
  //           icon: 'feather icon-user'
  //         },
  //         {
  //           id: 'forget-password',
  //           title: 'Forget Password',
  //           type: 'item',
  //           url: '/auth/forget-password',
  //           classes: 'nav-item',
  //           icon: 'feather icon-user'
  //         }
  //       ]
  //     },

  //   ]
  // }


  // {
  //   id: 'pages',
  //   title: 'Pages',
  //   type: 'group',
  //   icon: 'feather icon-file-text',
  //   children: [
  //     {
  //       id: 'auth',
  //       title: 'Authentication',
  //       type: 'collapse',
  //       icon: 'feather icon-lock',
  //       children: [
  //         {
  //           id: 'signup',
  //           title: 'Sign up',
  //           type: 'item',
  //           url: '/auth/signup',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'signin',
  //           title: 'Sign in',
  //           type: 'item',
  //           url: '/auth/signin',
  //           target: true,
  //           breadcrumbs: false
  //         }
  //       ]
  //     },

  //   ]
  // }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
